<!--
 * @Author: stone
 * @Date: 2021-06-11 11:02:01
 * @Description:
-->
<template>
  <div class="container">
    <div class="aside g-bg">
      <img
        style="width: 100%; height: 162px;"
        src="@/assets/images/user.png"
      >
      <div class="useritem">
        <div class="photo">
          <img
            style="width: 65px; height: 65px; border-radius: 50%;"
            :src="users.avatar"
          >
        </div>
        <!-- <div class="name">{{users.name}}</div> -->

        <div class="info">
          <span class="title">姓 名</span>
          <span
            class="content"
            v-show="isEditName"
          >
            <el-input
              v-model="users.name"
              placeholder="输入姓名"
              @keyup.enter.native="onEditNameClick"
              style="margin-top: -5px;"
            />
          </span>
          <span
            class="content"
            v-show="!isEditName"
          >{{users.name}}</span>

          <i
            v-show="!isEditName"
            class="el-icon-edit"
            @click="onEditName"
          ></i>
          <i
            style="margin-top: 5px;"
            v-show="isEditName"
            class="el-icon-check"
            @click="onEditNameClick"
          ></i>

        </div>
        <div class="info">
          <span class="title">部 门</span>
          <span
            class="content"
            v-show="isEditDepart"
          >
            <el-cascader
              style="display: block; margin-top: -5px;"
              v-model="departId"
              :options="depTree"
              :props="{ checkStrictly: true, value: 'id', label: 'name', emitPath: false }"
              filterable
            ></el-cascader>

          </span>
          <span
            class="content"
            v-show="!isEditDepart"
          >{{ departName }}</span>

          <i
            v-show="!isEditDepart"
            class="el-icon-edit"
            @click="onEditDepart"
          ></i>
          <i
            style="margin-top: 5px;"
            v-show="isEditDepart"
            class="el-icon-check"
            @click="onEditDepartClick"
          ></i>
        </div>
        <div class="info">
          <span class="title">手机号</span>
          <span class="content">{{users.phone}}</span>
        </div>
        <!-- <div class="info">
          <span class="title">邮 箱</span>
          <span class="content">{{users.department.name}}</span>
        </div> -->
        <div class="info">
          <span class="title">职 务</span>
          <span
            class="content"
            v-show="isEditPost"
          >
            <el-select
              v-model="users.duties_id"
              placeholder="请选择您的职位"
            >
              <el-option
                v-for="item in postList"
                :key="item.id"
                :label="item.name"
                :value="String(item.id)"
              ></el-option>
            </el-select>
          </span>
          <span
            class="content"
            v-show="!isEditPost"
          >{{ post }}</span>
          <i
            v-show="!isEditPost"
            class="el-icon-edit"
            @click="isEditPost = true"
          ></i>
          <i
            style="margin-top: 5px;"
            v-show="isEditPost"
            class="el-icon-check"
            @click="onEditPostClick"
          ></i>
        </div>
        <div class="info">
          <span class="title">个人分工</span>
          <span
            class="content"
            v-show="isEditDuty"
          >
            <el-input
              type="textarea"
              :autosize="{ minRows: 3 }"
              v-model="users.duty"
              placeholder="输入个人分工"
              @keyup.enter.native="onEditDutyClick"
              style="margin-top: -5px;"
            />
          </span>
          <span
            class="content"
            v-show="!isEditDuty"
          >{{ duty }}</span>

          <i
            v-show="!isEditDuty"
            class="el-icon-edit"
            @click="isEditDuty = true"
          ></i>
          <i
            style="margin-top: 5px;"
            v-show="isEditDuty"
            class="el-icon-check"
            @click="onEditDutyClick"
          ></i>
        </div>
        <div class="info">
          <span class="title">获奖情况</span>
          <span
            class="content"
            v-show="isEditAwards"
          >
            <el-input
              type="textarea"
              :autosize="{ minRows: 3 }"
              v-model="users.awards"
              placeholder="输入获奖情况"
              @keyup.enter.native="onEditAwardsClick"
              style="margin-top: -5px;"
            />
          </span>
          <span
            class="content"
            v-show="!isEditAwards"
          >{{ awards }}</span>

          <i
            v-show="!isEditAwards"
            class="el-icon-edit"
            @click="isEditAwards = true"
          ></i>
          <i
            style="margin-top: 5px;"
            v-show="isEditAwards"
            class="el-icon-check"
            @click="onEditAwardsClick"
          ></i>
        </div>
      </div>
    </div>

    <div
      class="main g-bg"
      style="padding: 0 15px;"
    >
      <el-tabs v-model="activeName">
        <el-tab-pane
          label="我发布的成绩单"
          name="list"
        ></el-tab-pane>
        <el-tab-pane
          label="我填写的落实情况"
          name="action"
        ></el-tab-pane>
      </el-tabs>
      <div>
        <list-view v-if="activeName === 'list'"></list-view>
        <action-view v-if="activeName === 'action'"></action-view>
      </div>
      <!-- <div class="top g-bg">
        <div class="item">
          <div class="item1">
            <div class="left">
              <img
                class="icon"
                src="@/assets/images/completed.png"
              />
            </div>
            <div class="right">
              <div class="title">
                已完成
              </div>
              <div class="title2">
                {{taskcount1}}
              </div>
            </div>
          </div>

        </div>
        <div class="item">
          <div class="item1">
            <div class="left">
              <img
                class="icon"
                src="@/assets/images/incomplete.png"
              />
            </div>
            <div class="right">
              <div class="title">
                未完成
              </div>
              <div
                class="title2"
                style="color: #fe7e96;"
              >
                {{taskcount2}}
              </div>
            </div>
          </div>
        </div>
      </div> -->

      <!-- <div class="down g-bg">
        <div class="down-item1">
          <div class="down-title">
            我的消息
          </div>
          <div class="down-title1">
            所有消息
          </div>
        </div>
        <div style="width: 100%; text-align: center;">暂无消息</div>
      </div> -->

    </div>
  </div>

</template>

<script>
import { getDeptTree } from '@/api/user'
import { modifyUsers } from '@/api/users'
import { getBacklogPage } from '@/api/backlog'
import { getPostList } from '@/api/admin/post'
import ListView from './components/list'
import ActionView from './components/action'

export default {
  components: {
    ListView,
    ActionView,
  },
  data() {
    return {
      activeName: 'list',
      users: '',
      departName: '',
      departId: '',
      isEditName: false,
      isEditDepart: false,

      duty: '',
      awards: '',
      isEditDuty: false,
      isEditAwards: false,

      postList: [],
      post: [],
      isEditPost: false,

      taskcount1: 0,
      taskcount2: 0,
      taskcount3: 0,

      depTree: [],
    }
  },
  created() {
    this.users = this.$store.state.user.userInfo
    console.log(this.users)
    this.departName = this.users.department.name
    this.duty = this.users.duty
    this.awards = this.users.awards

    this.fetchDepList()
    this.fetchPostList()

    this.fetchBacklogList()
  },
  methods: {
    fetchDepList() {
      getDeptTree().then(res => {
        this.depTree = [res]
      })
    },
    fetchPostList() {
      getPostList().then(res => {
        this.postList = res
        this.post = res.find(item => item.id === parseInt(this.users.duties_id))?.name || '其他'
      })
    },
    fetchBacklogList() {
      getBacklogPage(this.users.department.id, null, 'dept').then(res => {
        var itemData = res.data
        for (var i = 0; i < itemData.length; i++) {
          var item = itemData[i]
          if (item.state === '已完成' || item.state === '超时完成') {
            this.taskcount1++
          } else {
            this.taskcount2++
          }
          // if (item.state === '已逾期') {
          //   this.taskcount2++
          // }
          // if (item.state === '进行中') {
          //   this.taskcount3++
          // }
        }
      })
    },

    onAdd() {
      this.$router.push({ name: 'news/detail', params: { id: 'new' } })
    },

    onEditName() {
      this.isEditName = true
    },
    onEditNameClick() {
      this.isEditName = false
      modifyUsers({
        name: this.users.name,
      }).then(res => {
        this.$store.dispatch('user/USER_LOGIN', res).then(res => {
          this.$emit('success')
        })
      })
    },

    onEditDepart() {
      this.isEditDepart = true
    },
    onEditDepartClick() {
      modifyUsers({
        department_id: this.departId,
      }).then(res => {
        this.$store.dispatch('user/USER_LOGIN', res).then(res => {
          this.users = this.$store.state.user.userInfo
          this.departName = this.users.department.name
          this.isEditDepart = false
        })
      })
    },
    onEditPostClick() {
      modifyUsers({
        duties_id: this.users.duties_id,
      }).then(res => {
        this.$store.dispatch('user/USER_LOGIN', res).then(res => {
          this.users = this.$store.state.user.userInfo
          this.post =
            this.postList.find(item => item.id === parseInt(this.users.duties_id))?.name || '其他'
          this.isEditPost = false
        })
      })
    },

    onEditAwardsClick() {
      modifyUsers({
        awards: this.users.awards,
      }).then(res => {
        this.$store.dispatch('user/USER_LOGIN', res).then(res => {
          this.users = this.$store.state.user.userInfo
          this.awards = this.users.awards
          this.isAwardsDuty = false
          this.$emit('success')
        })
      })
    },
    onEditDutyClick() {
      modifyUsers({
        duty: this.users.duty,
      }).then(res => {
        this.$store.dispatch('user/USER_LOGIN', res).then(res => {
          this.users = this.$store.state.user.userInfo
          this.duty = this.users.duty
          this.isEditDuty = false
        })
      })
    },
  },
}
</script>

<style lang="scss" scoped>
.container {
  display: flex;
  align-items: flex-start;
  padding: 0 10px;

  .aside {
    width: 360px;
    min-height: 570px;
    margin-right: 15px;
  }

  .useritem {
    display: flex;
    flex-direction: column;
    padding: 0 18px;

    .photo {
      width: 100%;
      margin-top: -35px;
      text-align: center;
    }

    .name {
      width: 100%;
      margin-top: 18px;
      // font-family: FZLanTingHeiS-R-GB;
      font-size: 15px;
      font-weight: bold;
      color: #2b2d42;
      text-align: center;
    }

    .info {
      display: flex;
      flex-direction: row;
      padding: 15px 0;
      line-height: 26px;
      border-bottom: 1px solid #dfe3e8;

      .title {
        width: 20%;
        font-size: 11px;
        font-weight: 400;
        color: #747e8f;
      }

      .content {
        width: 80%;
        font-size: 11px;
        font-weight: 400;
        color: #2b2d42;
      }
    }
  }

  .main {
    display: flex;
    flex: 1;
    flex-direction: column;
  }

  .top {
    display: flex;
    flex-direction: row;
    height: 165px;
    padding: 10px;

    .item {
      flex: 1;
      padding: 40px 0;
      border-right: 1px solid #dfe3e8;

      .item1 {
        display: flex;
        justify-content: center;

        .left {
          .icon {
            width: 65px;
            height: 65px;
          }
        }

        .right {
          margin-left: 28px;

          .title {
            margin-top: 12px;
            font-size: 11px;
            font-weight: 400;
            color: #747e8f;
          }

          .title2 {
            font-size: 23px;
            font-weight: bold;
            color: #1e8ce4;
          }
        }
      }
    }

    .item:last-child {
      border-right: 0 solid #dfe3e8;
    }
  }

  .down {
    padding: 20px;
    margin-top: 10px;

    .down-item1 {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      margin-bottom: 18px;

      .down-title {
        font-size: 14px;
        font-weight: bold;
        color: #2b2d42;
      }

      .down-title1 {
        font-size: 9px;
        font-weight: 400;
        color: #747e8f;
      }
    }

    .down-item2 {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      height: 38px;
      padding: 0 13px;
      margin-top: 10px;
      line-height: 38px;
      background: rgba(223, 227, 232, 0.26);
      border-radius: 6px;

      .down-item2unread {
        display: flex;

        .down-item2icon {
          width: 8px;
          height: 8px;
          margin-top: 15px;
          margin-right: 10px;
          background: #fc4141;
          border-radius: 50%;
        }

        .down-item2title {
          font-size: 10px;
          font-weight: 400;
          color: #2b2d42;
        }
      }

      .down-item2read {
        .down-item2title {
          font-size: 10px;
          font-weight: 400;
          color: #2b2d42;
        }
      }

      .down-item2title1 {
        font-size: 9px;
        font-weight: 400;
        color: #747e8f;
      }
    }
  }
}
</style>
