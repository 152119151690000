<!--
 * @Author: jiang
 * @Date: 2021-06-11 11:02:01
 * @Description:
-->
<template>
  <div class="g-p-20">
    <el-table :data="list">
      <el-table-column
        label='序号'
        type="index"
      ></el-table-column>
      <el-table-column
        label='任务说明'
        prop='name'
        show-overflow-tooltip=""
      ></el-table-column>
      <el-table-column
        label='状态'
        prop='state'
        width="180"
      ></el-table-column>
      <el-table-column
        label='发布日期'
        prop='created_at'
        width="180"
      ></el-table-column>
      <el-table-column
        label='操作'
        width="180"
        align="center"
      >
        <template slot-scope="{ row }">
          <b-btn-info @click="onInfo(row)"></b-btn-info>
        </template>
      </el-table-column>
    </el-table>

    <b-pagination
      :page="page"
      @change="fetchList"
    ></b-pagination>
  </div>
</template>

<script>
import { getUserTaskActionPage } from '@/api/task'
export default {
  data() {
    return {
      list: [],
      page: {
        current: 1,
        size: 15,
        total: 0,
      },
    }
  },
  computed: {
    userId() {
      return this.$store.getters['user/id']
    },
    deptId() {
      return this.$store.getters['user/deptId']
    },
  },
  created() {
    this.fetchList()
  },
  methods: {
    fetchList() {
      const parmas = {
        page: this.page.current,
      }
      getUserTaskActionPage(parmas).then(res => {
        this.list = res.data
        this.page.total = res.total
      })
    },
    onRefresh() {
      this.page.current = 1
      this.fetchList()
    },
    onInfo(row) {
      this.$router.push({
        path: '/task/info',
        query: { id: row.id, type: 'task' },
      })
    },
  },
}
</script>
